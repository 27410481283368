import React, { Component } from 'react'
import {
  Dimmer,
  Loader,
  Header,
  Icon,
  Label,
  Button,
  Dropdown,
  Menu,
  Divider,
  Modal,
  Statistic,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import _ from 'lodash'
import { UserApi } from 'trill-api-admin-client'

import FormErrorLabel from '../../components/FormErrorLabel'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import DataTable from '../../components/DataTable'
import UserCreateModal from '../../components/UserCreateModal'
import UserEditModal from '../../components/UserEditModal'
import UserRemoveModal from '../../components/UserRemoveModal'
import CancelablePromise from '../../CancelablePromise'
import LogLevel from '../../LogLevel'

const logger = LogLevel.getLogger('Users')
const userApi = new UserApi()
let getUsers
let putUser

/**
 * ユーザー一覧の状態
 */
const UserStatus = {
  /** すべて */
  ALL: 'all',
  /** ゴミ箱 */
  TRASH: 'trash',
}

class Users extends Component {
  state = {
    users: [],
    isBusy: false,
    status: UserStatus.ALL,
    isUserEditModalOpen: false,
    isUserRemoveModalOpen: false,
    editUser: null,
    removeUser: null,
    isUndoModalOpen: false,
    undoUser: null,
    apiError: null,
    undoApiError: null,
    postApiError: null,
    isFormSearchValid: false,
  }

  constructor(props) {
    super(props)

    _.each(UserStatus, status => {
      _.extend(this.state, {
        [status]: {
          currentPage: 1,
          itemsPerPage: 10,
          totalPages: 0,
          totalItems: 0,
          sorting: { role: 'desc' },
          filtering: {
            username: '',
            role: 'owner',
          },
        },
      })
    })
  }

  componentDidMount() {
    this.retrieveUsers()
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getUsers)) {
      getUsers.cancel()
    }
    if (!_.isNil(putUser)) {
      putUser.cancel()
    }
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInvalid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * テーブルの並び順を変更したときのハンドラ
   */
  handleDataTableSelectionChange = (event, { sort }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.sorting = sort

    this.setState({ [status]: tableData }, () => {
      this.retrieveUsers()
    })
  }

  /**
   * テーブルのページ情報を変更したときのハンドラ
   */
  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ [status]: tableData }, () => {
      this.retrieveUsers()
    })
  }

  /**
   * ユーザー名で絞り込むインプットの値変更したときのハンドラ
   */
  handleUserNameInputChange = (event, { value }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.username = value

    this.setState({ [status]: tableData })
  }

  /**
   * 権限で絞り込むドロップダウンの値を変更したときのハンドラ
   */
  handleRoleDropdownChange = (event, { value }) => {
    const status = this.state.status
    const tableData = this.state[status]
    tableData.filtering.role = value

    this.setState({ [status]: tableData })
  }

  /**
   * ユーザー削除モーダルを閉じたときのハンドラ
   */
  handleUserRemoveModalClose = () => {
    this.setState({
      isUserRemoveModalOpen: false,
      removeUser: null,
    })
  }

  /**
   * ユーザー編集モーダルを閉じたときのハンドラ
   */
  handleUserEditModalClose = () => {
    this.setState({
      isUserEditModalOpen: false,
      editUser: null,
    })
  }

  /**
   * すべて or ゴミ箱のメニューを選択したときのハンドラ
   */
  handleStatusMenuItemClick = (event, { name }) => {
    this.setState({ status: name }, () => {
      this.retrieveUsers()
    })
  }

  /**
   * ユーザーのデータに変更があったときのハンドラ
   */
  handleUserDataChanged = () => {
    this.retrieveUsers()
  }

  /**
   * 絞り込み検索ボタンを押したときのハンドラ
   */
  handleSearchButtonClick = event => {
    event.preventDefault()

    this.retrieveUsers()
  }

  /**
   * Undo モーダル画面の公開ボタンを押したときのハンドラ
   */
  handleUndoModalPublishButtonClick = () => {
    const userId = this.state.undoUser.id

    this.setState({
      isBusy: true,
      undoApiError: null,
    })

    putUser = CancelablePromise(userApi.putUser(userId))
    putUser.promise
      .then(() => {
        this.setState({
          isBusy: false,
          isUndoModalOpen: false,
          undoUser: null,
        })

        this.retrieveUsers()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`put user userId #${userId} error`, error)

        this.setState({
          isBusy: false,
          undoApiError: error,
        })
      })
  }

  /**
   * Undo モーダル画面を閉じたときのハンドラ
   */
  handleUndoModalClose = () => {
    this.setState({
      isUndoModalOpen: false,
      undoUser: null,
    })
  }

  /**
   * Undo モーダル画面のキャンセルボタンを押したときのハンドラ
   */
  handleUndoModalCancelButtonClick = () => {
    this.setState({
      isUndoModalOpen: false,
      undoUser: null,
    })
  }

  /**
   * Undo モーダル画面を表示する関数
   * @param {Object} user - ユーザーデータ
   */
  openUndoModal(user) {
    this.setState({
      isUndoModalOpen: true,
      undoUser: user,
    })
  }

  /**
   * ユーザー編集モーダルを開く関数
   * @param {Object} user - ユーザーデータ
   */
  openUserEditModal(user) {
    this.setState({
      editUser: user,
      isUserEditModalOpen: true,
    })
  }

  /**
   * ユーザー削除モーダルを開く関数
   * @param {Object} user - ユーザーデータ
   */
  openUserRemoveModal(user) {
    this.setState({
      removeUser: user,
      isUserRemoveModalOpen: true,
    })
  }

  /**
   * ユーザー一覧を取得
   */
  retrieveUsers = () => {
    const status = this.state.status
    const tableData = this.state[status]

    this.setState({
      isBusy: true,
      apiError: null,
    })

    getUsers = CancelablePromise(userApi.getUsers(this.getRequestQuery()))
    getUsers.promise
      .then(response => {
        logger.debug('retrieve users response', response)

        const users = response.data
        const responseHeader = response.header
        tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
        tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)
        tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
        tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 10), 10)

        this.setState({
          isBusy: false,
          users,
          [status]: tableData,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('retrieve users get users error', error)

        tableData.itemsPerPage = 10
        tableData.currentPage = 1
        tableData.totalPages = 0
        tableData.totalItems = 0

        this.setState({
          isBusy: false,
          users: [],
          [status]: tableData,
          apiError: error,
        })
      })
  }

  /**
   * API 取得時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const status = this.state.status
    const tableData = this.state[status]

    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    // 1 ページあたりの表示件数
    const itemsPerPage = tableData.itemsPerPage

    // ソート順
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    // 絞り込み
    const filtering = []
    if (!_.isEmpty(tableData.filtering.username)) {
      let filteringUsername = tableData.filtering.username
      if (filteringUsername.match(/\,/)) { // eslint-disable-line
        filteringUsername = ''
      }

      const username = `username LIKE '%${filteringUsername}%'`
      filtering.push(username)
    }

    if (!_.isEmpty(tableData.filtering.role)) {
      const role = `role = '${tableData.filtering.role}'`
      filtering.push(role)
    }

    if (_.isEqual(status, UserStatus.TRASH)) {
      filtering.push('deletedAt IS NOT NULL')
    } else {
      filtering.push('deletedAt IS NULL')
    }

    const query = {
      currentPage,
      itemsPerPage,
      sorting,
      filtering,
    }

    logger.debug('get request query', query)

    // 数字以外の空文字は除外して返却
    return _.omitBy(query, value => !_.isNumber(value) && _.isEmpty(value))
  }

  postUser = async newUser => {
    this.setState({ postApiError: null })

    try {
      const response = await userApi.postUser(newUser)

      logger.debug(`Post user called successfully. Response: ${response}`)

      this.retrieveUsers()
    } catch (error) {
      logger.error(`Post user error`, error)

      this.setState({ postApiError: error })
    }
  }

  render() {
    const { isBusy, users, status, postApiError } = this.state
    const tableData = this.state[status]

    return (
      <div className="Users">
        <Header as="h1">
          <Icon name="users" />

          <Header.Content>ユーザー</Header.Content>
        </Header>

        <Dimmer active={isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        {/* すべて・ゴミ箱切り替えメニュー */}
        <Menu pointing secondary floated>
          <Menu.Item
            content="すべて"
            name={UserStatus.ALL}
            active={_.isEqual(status, UserStatus.ALL)}
            onClick={this.handleStatusMenuItemClick}
          />

          <Menu.Item
            content="ゴミ箱"
            name={UserStatus.TRASH}
            active={_.isEqual(status, UserStatus.TRASH)}
            onClick={this.handleStatusMenuItemClick}
          />
        </Menu>

        <Menu secondary floated="right">
          <Menu.Item>
            <UserCreateModal apiError={postApiError} postUser={this.postUser} />
          </Menu.Item>
        </Menu>

        <Divider hidden clearing />

        {/* 検索 */}
        <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInvalid}>
          <Form.Field width={8}>
            <Input
              name="users-search"
              type="text"
              placeholder="ユーザー名で検索"
              action
              fluid
              value={tableData.filtering.username}
              onChange={this.handleUserNameInputChange}
              validations={{ matchRegexp: /^((?!,).)*$/i }}
              validationErrors={{
                matchRegexp: 'キーワードに不正な記号があるため検索できません',
              }}
              errorLabel={<FormErrorLabel />}
            >
              <input />

              <Dropdown
                onChange={this.handleRoleDropdownChange}
                placeholder="権限で検索"
                selection
                value={tableData.filtering.role}
                options={[
                  {
                    text: '解除',
                    value: '',
                  },
                  {
                    text: 'ユーザー',
                    value: 'user',
                  },
                  {
                    text: '閲覧者',
                    value: 'viewer',
                  },
                  {
                    text: 'アナリスト',
                    value: 'analyst',
                  },
                  {
                    text: '広告編集者',
                    value: 'ad_manager',
                  },
                  {
                    text: '編集者',
                    value: 'editor',
                  },
                  {
                    text: 'オーナー',
                    value: 'owner',
                  },
                ]}
              />
              <Button
                icon="search"
                onClick={this.handleSearchButtonClick}
                color="blue"
                disabled={!this.state.isFormSearchValid}
              />
            </Input>
          </Form.Field>
        </Form>

        {/* 取得件数 */}
        {_.isNil(this.state.apiError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {/* エラーメッセージ */}
        <ApiErrorMessage error={this.state.apiError} />

        {!_.isEmpty(users) && (
          <DataTable
            sort={tableData.sorting}
            rowKey="id"
            items={users}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            itemsPerPage={tableData.itemsPerPage}
            onSelectionChange={this.handleDataTableSelectionChange}
            onPageChange={this.handleDataTablePageChange}
            columns={[
              {
                label: '名前',
                field: 'username',
                collapsing: false,
              },
              {
                label: '権限',
                field: 'role',
                render: user => {
                  const role = _.defaultTo(user.role, '')
                  const isUser = _.isEqual(role, 'user')
                  const isViewer = _.isEqual(role, 'viewer')
                  const isAnalyst = _.isEqual(role, 'analyst')
                  const isAdManager = _.isEqual(role, 'ad_manager')
                  const isEditor = _.isEqual(role, 'editor')
                  const isOwner = _.isEqual(role, 'owner')
                  return (
                    <Label.Group size="medium">
                      {isUser && <Label color="olive" content="ユーザー" horizontal />}

                      {isViewer && <Label color="yellow" content="閲覧者" horizontal />}

                      {isAnalyst && <Label color="teal" content="アナリスト" horizontal />}

                      {isAdManager && <Label color="green" content="広告編集者" horizontal />}

                      {isEditor && <Label color="orange" content="編集者" horizontal />}

                      {isOwner && <Label color="red" content="オーナー" horizontal />}
                    </Label.Group>
                  )
                },
              },
              {
                label: '操作',
                align: 'center',
                render: user => (
                  <Button.Group key={user.id} secondary>
                    <Button
                      icon="edit"
                      onClick={() => {
                        this.openUserEditModal(user)
                      }}
                    />

                    {!_.isEqual(status, UserStatus.TRASH) && (
                      <Button
                        icon="trash alternate outline"
                        onClick={() => {
                          this.openUserRemoveModal(user)
                        }}
                      />
                    )}

                    {_.isEqual(status, UserStatus.TRASH) && (
                      <Button
                        icon="undo"
                        onClick={() => {
                          this.openUndoModal(user)
                        }}
                      />
                    )}
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {/* ユーザー更新モーダル */}
        <UserEditModal
          user={this.state.editUser}
          open={this.state.isUserEditModalOpen}
          onClose={this.handleUserEditModalClose}
          onSuccessDataChanged={this.handleUserDataChanged}
        />

        {/* ユーザー削除モーダル */}
        <UserRemoveModal
          user={this.state.removeUser}
          open={this.state.isUserRemoveModalOpen}
          onClose={this.handleUserRemoveModalClose}
          onSuccessDataChanged={this.handleUserDataChanged}
        />

        {/* 削除したユーザーを戻すときに表示する確認用モーダル */}
        <Modal
          size="tiny"
          closeIcon={true}
          open={this.state.isUndoModalOpen}
          onClose={this.handleUndoModalClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>ユーザーの公開</Modal.Header>

          {/* ローディング */}
          <Dimmer active={this.state.isBusy} inverted>
            <Loader />
          </Dimmer>

          <Modal.Content>
            {/* エラーメッセージ */}
            <ApiErrorMessage error={this.state.undoApiError} />
            {_.get(this.state, 'undoUser.username', 'ユーザー')} を戻しますか？
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleUndoModalCancelButtonClick} />

            <Button positive content="戻す" onClick={this.handleUndoModalPublishButtonClick} />
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default Users
