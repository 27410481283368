/* eslint-disable prefer-const */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react'
import {
  Button,
  Container,
  Dimmer,
  Divider,
  Header,
  Icon,
  Loader,
  Modal,
  Segment,
  List,
  Popup,
  Grid,
  Label,
  Statistic,
  Step,
} from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import _ from 'lodash'
import moment from 'moment'
import encoding from 'encoding-japanese'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'

import { AuthorizedDigitalSellerApi } from 'trill-api-admin-client'

import DataTable from '../../../components/DataTable'
import ApiErrorMessage from '../../../components/ApiErrorMessage'
import FormErrorLabel from '../../../components/FormErrorLabel'

import GetPermission from '../../../GetPermission'
import CancelablePromise from '../../../CancelablePromise'

const authorizedDigitalSellerApi = new AuthorizedDigitalSellerApi()
let getAdsText
let sendAdsText
let getAdsTextVersions

const DIFF_VIEWER_STYLES = {
  diffContainer: {
    border: '1px solid #eee',
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  titleBlock: {
    fontWeight: 'bold',
    fontSize: '1em',
    width: '50%',
    paddingLeft: '60px',
  },
  gutter: {
    backgroundColor: '#f0f0f0',
    borderRight: '1px solid #ddd',
  },
}

const RECORD_TYPES = {
  DATA: 'DATA',
  VARIABLE: 'VARIABLE',
  COMMENT: 'COMMENT',
}

const RECORD_TYPE_REGEXPS = {
  DATA: /^([^,#]+)\s*,\s*([^,#]+)\s*,\s*([^,#]+)\s*(,\s*([^,#]+)\s*)?(\s*#([^,]+)\s*)?$/i,
  VARIABLE: /^([^=]+)\s*=\s*(.+)$/i,
  COMMENT: /^#(.+)$/i,
}

const RELATIONSHIP_TYPES = {
  DIRECT: 'DIRECT',
  RESELLER: 'RESELLER',
}

const VARIABLE_NAMES = {
  CONTACT: 'CONTACT',
  SUBDOMAIN: 'SUBDOMAIN',
  INVENTORYPARTNERDOMAIN: 'INVENTORYPARTNERDOMAIN',
  OWNERDOMAIN: 'OWNERDOMAIN',
  MANAGERDOMAIN: 'MANAGERDOMAIN',
}

const RECORD_ERRORS = {
  INVALID_RECORD_FORMAT: {
    code: 'invalid_record_format',
    message:
      '無効なレコード形式です。レコードに含まれるカンマ区切りの値が 3 つ未満であるため、形式が不適切です。',
  },
  INVALID_VARIABLE_NAME: {
    code: 'invalid_variable_name',
    message: `変数名が無効です。変数名の値は、${[_.dropRight(_.values(VARIABLE_NAMES)).join('、'), _.last(_.values(VARIABLE_NAMES))].join('、または ')} である必要があります。`,
  },
  INVALID_RELATIONSHIP_TYPE: {
    code: 'invalid_relationship_type',
    message: `無効な関係です。関係値は ${_.values(RELATIONSHIP_TYPES).join(' または ')} である必要があります。`,
  },
  INVALID_CERTIFICATION: {
    code: 'invalid_certification',
    message:
      '無効な認証局 ID です。数字と小文字のみを含めることができ、9 文字または 16 文字にする必要があります。',
  },
}

const RECORD_WARNING = {
  DUPLICATE_RECORD: {
    code: 'duplicate_record',
    message: 'レコードが重複しています。重複するデータ行には、$lineNumbers が含まれます。',
  },
}

function getAuthorizedDigitalSellers(content) {
  const items = _.map(_.split(content, '\n'), (data, index) => {
    let errors = []
    let warnings = []

    let matchData
    let name
    let publisher
    let relationship
    let certification
    let value
    let comment
      ;[matchData, comment] = data.match(RECORD_TYPE_REGEXPS.COMMENT) || []
    if (matchData) {
      comment = comment.trim()

      return {
        index: index + 1,
        type: RECORD_TYPES.COMMENT,
        comment,
        content: data,
        errors,
        warnings,
      }
    }

    ;[matchData, name, value] = data.match(RECORD_TYPE_REGEXPS.VARIABLE) || []
    if (matchData) {
      name = name.trim()
      value = value.trim()

      if (_.isEmpty(VARIABLE_NAMES[name.toUpperCase()])) {
        warnings.push(RECORD_ERRORS.INVALID_VARIABLE_NAME)
      }

      return {
        index: index + 1,
        type: RECORD_TYPES.VARIABLE,
        name,
        value,
        content: data,
        errors,
        warnings,
      }
    }

    ;[matchData, name, publisher, relationship, , certification, , comment] = data.match(RECORD_TYPE_REGEXPS.DATA) || []
    if (matchData) {
      name = name.trim()
      publisher = publisher.trim()
      relationship = relationship.trim()
      certification = certification?.trim()
      comment = comment?.trim()

      if (_.isEmpty(RELATIONSHIP_TYPES[relationship.toUpperCase()])) {
        errors.push(RECORD_ERRORS.INVALID_RELATIONSHIP_TYPE)
      }

      const isValidCertification = !certification || certification.match(/^[a-z0-9]{9,16}$/)
      if (!isValidCertification) {
        errors.push(RECORD_ERRORS.INVALID_CERTIFICATION)
      }

      return {
        index: index + 1,
        type: RECORD_TYPES.DATA,
        name,
        publisher,
        relationship,
        certification,
        comment,
        content: data,
        errors,
        warnings,
      }
    }

    if (!_.isEmpty(data)) {
      errors.push(RECORD_ERRORS.INVALID_RECORD_FORMAT)
    }

    return {
      index: index + 1,
      content: data,
      errors,
      warnings,
    }
  })

  const groupItemKey = item =>
    `${item.type}-${item.name}-${item.value}-${item.publisher}-${item.relationship}-${item.certification}`
  const filteredItems = _.filter(items, item => !_.isEmpty(item.content))
  const groupItemObject = _.groupBy(filteredItems, item => groupItemKey(item))

  _.each(filteredItems, item => {
    const groupItems = groupItemObject[groupItemKey(item)]
    const filteredGroupItems = _.isEmpty(item.type)
      ? _.filter(groupItems, groupItem => _.isEqual(groupItem.content, item.content))
      : groupItems

    if (!_.isEqual(filteredGroupItems.length, 1)) {
      item.warnings.push({
        ...RECORD_WARNING.DUPLICATE_RECORD,
        message: RECORD_WARNING.DUPLICATE_RECORD.message.replace(
          '$lineNumbers',
          filteredGroupItems.map(groupItem => groupItem.index).join('、'),
        ),
      })
    }
  })

  return items
}

class AuthorizedDigitalSellers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isBusy: false,
      apiError: null,
      permission: GetPermission('authorizedDigitalSeller'),

      authorizedDigitalSellerVersions: [],
      tableData: {
        totalPages: 0,
        totalItems: 0,
        currentPage: 1,
        itemsPerPage: 10,
        sorting: {
          index: 'desc',
        },
      },

      authorizedDigitalSeller: null,

      isEditModalOpen: false,
      isEditFormValid: false,
      editFormStep: 0,
      editFormData: {
        content: '',
        metadata: {
          comment: '',
        },
      },
    }
  }

  componentDidMount() {
    this.retrieveAdsTextVersions()
  }

  componentWillUnmount() {
    if (!_.isNil(getAdsText)) {
      getAdsText.cancel()
    }

    if (!_.isNil(sendAdsText)) {
      sendAdsText.cancel()
    }

    if (!_.isNil(getAdsTextVersions)) {
      getAdsTextVersions.cancel()
    }
  }

  retrieveAdsTextVersions() {
    this.setState({
      isBusy: true,
      apiError: null,
    })

    getAdsTextVersions = CancelablePromise(authorizedDigitalSellerApi.getAdsTextVersions())
    getAdsTextVersions.promise
      .then(response => {
        const { tableData } = this.state

        const authorizedDigitalSellerVersions = _.map(_.orderBy(_.get(response, 'data', []), ['lastModified'], ['asc']), (version, index) => ({
          index: index + 1,
          ...version,
        }))
        tableData.totalItems = authorizedDigitalSellerVersions.length
        tableData.totalPages = Math.ceil(tableData.totalItems / tableData.itemsPerPage)

        this.setState({
          authorizedDigitalSellerVersions,
          tableData,
        })
        this.retrieveAdsText(
          null,
          authorizedDigitalSeller => {
            this.setState({
              isBusy: false,
              authorizedDigitalSeller,
            })
          },
          error => {
            this.setState({
              isBusy: false,
              apiError: error,
            })
          }
        )
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  retrieveAdsText(version, successCallback, errorCallBack) {
    const queryParams = _.omitBy({ version }, value => !_.isNumber(value) && _.isEmpty(value))
    getAdsText = CancelablePromise(authorizedDigitalSellerApi.getAdsText(queryParams))
    getAdsText.promise
      .then(response => {
        const authorizedDigitalSeller = _.get(response, 'data', null)

        if (successCallback) {
          successCallback(authorizedDigitalSeller)
        }
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        if (errorCallBack) {
          errorCallBack(error)
        }
      })
  }

  handleEditModalOpen() {
    const { authorizedDigitalSeller } = this.state

    this.setState({
      apiError: null,
      isEditModalOpen: true,
      editFormStep: 0,
      editFormData: {
        ..._.pick(authorizedDigitalSeller, ['content', 'metadata.comment']),
        metadata: {
          comment: '',
        }
      },
    })
  }

  handleEditModalClose() {
    this.setState({
      apiError: null,
      isEditModalOpen: false,
      editFormStep: 0,
      editFormData: {
        content: '',
        metadata: {
          comment: '',
        },
      },
    })
  }

  handleCancelButtonEditModalClick() {
    this.setState({
      isEditModalOpen: false,
      editFormStep: 0,
      editFormData: {
        content: '',
        metadata: {
          comment: '',
        },
      },
    })
  }

  handleVersionChangesModalOpen(authorizedDigitalSellerChanges) {
    this.setState({
      authorizedDigitalSellerChanges,
      isVersionChangesModalOpen: true,
    })
  }

  handleVersionChangesModalClose() {
    this.setState({
      authorizedDigitalSellerChanges: null,
      isVersionChangesModalOpen: false,
    })
  }

  handleSubmitButtonEditModalClick() {
    const { editFormData } = this.state

    this.setState({
      isBusy: true,
      apiError: null,
    })

    sendAdsText = CancelablePromise(authorizedDigitalSellerApi.patchAdsText(editFormData))
    sendAdsText.promise
      .then(() => {
        this.handleEditModalClose()
        this.retrieveAdsTextVersions()
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  handleEditFormDataChange(key, value) {
    const { editFormData } = this.state
    _.set(editFormData, key, value)

    this.setState({ editFormData })
  }

  handleStepButtonEditModalClick(editFormStep) {
    this.setState({ editFormStep })
  }

  handleEditFormValid() {
    this.setState({ isEditFormValid: true })
  }

  handleEditFormInvalid() {
    this.setState({ isEditFormValid: false })
  }

  handleDownloadAuthorizedDigitalSellerContent(content) {
    const uniArray = encoding.stringToCode(content)
    const sjisArray = encoding.convert(uniArray, {
      to: 'UTF-8',
      from: 'UNICODE',
    })
    const unit8Array = new Uint8Array(sjisArray)
    /**
     * BOM document https://en.wikipedia.org/wiki/Byte_order_mark#UTF-8
     * Prepend UTF-8 BOM (hexadecimal) byte sequence EF BB BF to data
     */
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), unit8Array], { type: 'text/plain' })

    const link = document.createElement('a')
    const url = (window.URL || window.webkitURL).createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('charset', 'utf-8')
    link.setAttribute('download', 'ads.txt')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setTimeout(() => (window.URL || window.webkitURL).revokeObjectURL(link.href), 200)
  }

  handleDownloadButtonClick() {
    const content = _.get(this.state, 'authorizedDigitalSeller.content', '')

    this.handleDownloadAuthorizedDigitalSellerContent(content)
  }

  handleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const { tableData } = this.state

    tableData.totalPages = Math.ceil(tableData.totalItems / itemsPerPage)
    tableData.itemsPerPage = itemsPerPage
    tableData.currentPage = Math.min(tableData.totalPages, currentPage)

    this.setState({ tableData })
  }

  handleDataTableSortChange = (event, { sort }) => {
    const { tableData } = this.state
    tableData.sorting = sort

    this.setState({ tableData })
  }

  handleDetailVersionButtonClick(item) {
    const { version } = item

    this.setState({
      isBusy: true,
      apiError: null,
    })
    this.retrieveAdsText(
      version,
      authorizedDigitalSeller => {
        const authorizedDigitalSellerChanges = {
          old: {
            title: `バージョン ${version}`,
            ...authorizedDigitalSeller,
          },
          new: {
            title: '現行バージョン',
            ...this.state.authorizedDigitalSeller,
          },
        }
        this.handleVersionChangesModalOpen(authorizedDigitalSellerChanges)

        this.setState({
          isBusy: false,
        })
      },
      error => {
        this.setState({
          isBusy: false,
          apiError: error,
        })
      },
    )
  }

  handleDownloadAuthorizedDigitalSellerVersionButtonClick(item) {
    const { version } = item

    this.setState({
      isBusy: true,
      apiError: null,
    })
    this.retrieveAdsText(
      version,
      authorizedDigitalSeller => {
        const content = _.get(authorizedDigitalSeller, 'content', '')

        this.handleDownloadAuthorizedDigitalSellerContent(content)
        this.setState({
          isBusy: false,
        })
      },
      error => {
        this.setState({
          isBusy: false,
          apiError: error,
        })
      },
    )
  }

  authorizedDigitalSellerVersionsDisplayed() {
    const {
      authorizedDigitalSellerVersions,
      tableData: { currentPage, itemsPerPage, sorting },
    } = this.state

    const sortedKeys = _.keys(sorting)
    const sortedOrders = _.values(sorting)
    const sortedArr = _.orderBy(authorizedDigitalSellerVersions, sortedKeys, sortedOrders);

    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = currentPage * itemsPerPage
    const filterArr = sortedArr.slice(startIndex, endIndex)

    return filterArr
  }

  renderEditAuthorizedDigitalSellerModal() {
    const { apiError, authorizedDigitalSeller, isEditModalOpen, isEditFormValid, editFormStep, editFormData } = this.state
    const apiPatchError = isEditModalOpen ? apiError : null

    const initialData = {
      ..._.pick(authorizedDigitalSeller, ['content', 'metadata.comment']),
      metadata: {
        comment: '',
      },
    }
    const isEditFormModified = !_.isEqual(editFormData, initialData)

    const editAuthorizedDigitalSellers = getAuthorizedDigitalSellers(_.get(editFormData, 'content', ''))

    const steps = [
      {
        key: 0,
        active: _.isEqual(editFormStep, 0),
        completed: !_.isEqual(editFormStep, 0),
        icon: {
          name: 'file text outline',
          style: {
            color: !_.isEqual(editFormStep, 0) ? '#21ba45' : '#4183c4',
          },
        },
        title: {
          content: 'STEP① Ads.txtの更新',
          style: {
            color: !_.isEqual(editFormStep, 0) ? '#21ba45' : '#4183c4',
            fontSize: '1.4em'
          },
        },
        description: 'ads.txtファイルの内容を変更する',
        onClick: () => this.handleStepButtonEditModalClick(0),
      },
      {
        key: 1,
        active: _.isEqual(editFormStep, 1),
        disabled: !isEditFormValid || !isEditFormModified,
        icon: {
          name: 'comment alternate outline',
          style: {
            color: !isEditFormValid || !isEditFormModified ? 'rgba(40 40 40 / 30%)' : 'rgb(222 31 82 / 87%)',
          },
        },
        title: {
          content: 'STEP② タイトルの設定',
          style: {
            color: !isEditFormValid || !isEditFormModified ? 'rgba(40 40 40 / 30%)' : 'rgb(222 31 82 / 87%)',
            fontSize: '1.4em'
          },
        },
        description: 'これらの変更にタイトルを設定する',
        onClick: () => this.handleStepButtonEditModalClick(1),
      },
    ]

    const textareaBaseStyles = {
      margin: 0,
      fontFamily: 'sans-serif',
      fontSize: '1em',
      lineHeight: 1.5,
      height: 'calc(100vh - 26em)',
      whiteSpace: 'nowrap',
    }
    const textareaNumLineStyles = {
      padding: '1em 0.5em',
      borderTopLeftRadius: '0.25rem',
      borderBottomLeftRadius: '0.25rem',
      textAlign: 'right',
      backgroundColor: 'rgba(0, 0, 0, .1)',
      overflow: 'hidden',
      ...textareaBaseStyles,
    }
    const textareaInputStyles = {
      padding: '1em',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      minHeight: 'calc(100vh - 26em)',
      maxHeight: 'calc(100vh - 26em)',
      overflow: 'auto',
      ...textareaBaseStyles,
    }

    const textareaInputElement = document.querySelector('#edit-modal-content-textarea-input-id')
    const textareaNumLineElement = document.querySelector('#edit-modal-content-textarea-num-line-id')
    if (textareaInputElement && textareaNumLineElement) {
      textareaInputElement.addEventListener('scroll', () => {
        textareaNumLineElement.scrollTop = textareaInputElement.scrollTop;
      });
    }

    const handleScrollToLine = item => {
      const { index } = item

      if (!textareaInputElement) {
        return
      }

      const topPosition = ((index - 1) * 1.5 + 1) * parseFloat(getComputedStyle(textareaInputElement).fontSize)
      textareaInputElement.scrollTo({
        top: topPosition,
        behavior: 'smooth',
      })
    }

    return (
      <Modal
        closeIcon
        className="AuthorizedDigitalSellers__EditModal"
        size="fullscreen"
        open={isEditModalOpen}
        onClose={this.handleEditModalClose.bind(this)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>ads.txtの編集</Modal.Header>

        <Modal.Content>
          <Form
            noValidate
            size="small"
            onValid={this.handleEditFormValid.bind(this)}
            onInvalid={this.handleEditFormInvalid.bind(this)}
            onValidSubmit={this.handleSubmitButtonEditModalClick.bind(this)}
          >
            <Step.Group
              size="tiny"
              widths={steps.length}
              style={{
                display: 'flex',
                width: '75%',
                margin: '0 auto 2em',
              }}
            >
              {steps.map(step => (
                <Step key={step.key} active={step.active} disabled={step.disabled} completed={step.completed}>
                  <Icon {...step.icon}/>
                  <Step.Content>
                    <Step.Title {...step.title} />
                    <Step.Description>{step.description}</Step.Description>
                  </Step.Content>
                </Step>
              ))}
            </Step.Group>

            <ApiErrorMessage error={apiPatchError} />

            <Grid columns="2" doubling className={`${steps[0].active ? '' : 'isHidden'}`}>
              <Grid.Column width={10} style={{ paddingRight: 0 }}>
                <Form.Field required>
                  <label style={{ fontSize: '1em' }}>ads.txt</label>

                  <div style={{ display: 'flex' }}>
                    <List
                      id="edit-modal-content-textarea-num-line-id"
                      size="small"
                      style={textareaNumLineStyles}
                    >
                      {_.map(editAuthorizedDigitalSellers, editAuthorizedDigitalSeller => (
                        <List.Item
                          key={editAuthorizedDigitalSeller.index}
                          content={editAuthorizedDigitalSeller.index}
                          style={{ padding: 0, lineHeight: 1.5 }}
                        />
                      ))}
                    </List>

                    <textarea
                      id="edit-modal-content-textarea-input-id"
                      name="content"
                      placeholder="ads.txtを入力してください"
                      style={textareaInputStyles}
                      value={_.get(editFormData, 'content', '')}
                      onChange={event => this.handleEditFormDataChange('content', event.target.value)}
                    />
                  </div>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={6}>
                <Segment>
                  <Label attached="top" color="red" content="エラー" icon="warning circle" />

                  {this.renderErrorsFormContentValidate(editAuthorizedDigitalSellers, handleScrollToLine)}
                </Segment>

                <Segment>
                  <Label attached="top" color="orange" content="警告" icon="warning sign" />

                  {this.renderWarningsFormContentValidate(editAuthorizedDigitalSellers, handleScrollToLine)}
                </Segment>
              </Grid.Column>
            </Grid>

            <Container className={`${steps[1].active ? '' : 'isHidden'}`}>
              <Form.Input
                name="metadata.comment"
                label="タイトル"
                placeholder="タイトルを入力してください"
                value={_.get(editFormData, 'metadata.comment', '')}
                validations="maxLength:255"
                validationErrors={{ maxLength: '255 文字以内で入力してください。' }}
                errorLabel={<FormErrorLabel />}
                onChange={event => this.handleEditFormDataChange('metadata.comment', event.target.value)}
              />

              <Divider hidden clearing />

              <Segment style={{ padding: 0, minHeight: '50vh', maxHeight: '55vh', overflow: 'auto' }}>
                <Label
                  color="blue"
                  content="ads.txtの変更"
                  style={{
                    position: 'sticky',
                    zIndex: 1,
                    width: '100%',
                    top: 0,
                    left: 0,
                    margin: 0,
                    padding: '0.75em 1em',
                    borderRadius: '0.25em 0.25em 0 0',
                  }}
                />

                <ReactDiffViewer
                  oldValue={initialData.content}
                  newValue={editFormData.content}
                  compareMethod={DiffMethod.WORDS}
                  styles={DIFF_VIEWER_STYLES}
                />
              </Segment>
            </Container>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            icon="cancel"
            content="キャンセル"
            onClick={this.handleCancelButtonEditModalClick.bind(this)}
          />
          <Button
            positive
            icon="left arrow"
            content="前へ"
            labelPosition="left"
            className={`${steps[0].active ? 'isHidden' : ''}`}
            onClick={() => this.handleStepButtonEditModalClick(editFormStep - 1)}
          />
          <Button
            positive
            icon="right arrow"
            content="次へ"
            labelPosition="right"
            className={`${steps[steps.length - 1].active ? 'isHidden' : ''}`}
            disabled={_.get(steps[editFormStep + 1], 'disabled', false)}
            onClick={() => this.handleStepButtonEditModalClick(editFormStep + 1)}
          />
          <Button
            primary
            icon="save"
            content="公開"
            className={`${steps[steps.length - 1].active ? '' : 'isHidden'}`}
            disabled={steps[steps.length - 1].disabled}
            onClick={this.handleSubmitButtonEditModalClick.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    )
  }

  renderErrorsFormContentValidate(authorizedDigitalSellers, onScrollToLine) {
    return (
      <List divided size="small" style={{ height: 'calc(50vh - 17.5em)', overflow: 'auto' }}>
        {_.map(
          _.filter(authorizedDigitalSellers, authorizedDigitalSeller => !_.isEmpty(authorizedDigitalSeller.errors)),
          authorizedDigitalSeller => {
            const { index, content, errors } = authorizedDigitalSeller

            return (
              <List.Item
                key={index}
                style={{ padding: '0.75em 0 0.25em' }}
                onClick={() => onScrollToLine(authorizedDigitalSeller)}
              >
                <List.Content>
                  <List.Header style={{ cursor: 'pointer' }} onClick={() => onScrollToLine(index)}>
                    Line {index}: <Label>{content}</Label>
                  </List.Header>
                  <List.List as="ul" style={{ margin: '0.5em 0' }}>
                    {_.map(errors, error => (
                      <List.Item key={error.code} as="li" style={{ color: '#db2828' }}>
                        {error.message}
                      </List.Item>
                    ))}
                  </List.List>
                </List.Content>
              </List.Item>
            )
          },
        )}
      </List>
    )
  }

  renderWarningsFormContentValidate(authorizedDigitalSellers, onScrollToLine) {
    return (
      <List divided size="small" style={{ height: 'calc(50vh - 17.5em)', overflow: 'auto' }}>
        {_.map(
          _.filter(authorizedDigitalSellers, authorizedDigitalSeller => !_.isEmpty(authorizedDigitalSeller.warnings)),
          authorizedDigitalSeller => {
            const { index, content, warnings } = authorizedDigitalSeller

            return (
              <List.Item key={index} style={{ padding: '0.75em 0 0.25em' }}>
                <List.Content>
                  <List.Header style={{ cursor: 'pointer' }} onClick={() => onScrollToLine(authorizedDigitalSeller)}>
                    Line {index}: <Label>{content}</Label>
                  </List.Header>
                  <List.List as="ul" style={{ margin: '0.5em 0' }}>
                    {_.map(warnings, warning => (
                      <List.Item key={warning.code} as="li" style={{ color: '#f2711c' }}>
                        {warning.message}
                      </List.Item>
                    ))}
                  </List.List>
                </List.Content>
              </List.Item>
            )
          },
        )}
      </List>
    )
  }

  renderAuthorizedDigitalSellerVersionChangesModal() {
    const { isVersionChangesModalOpen, authorizedDigitalSellerChanges } = this.state
    const oldTitle = _.get(authorizedDigitalSellerChanges, 'old.title', '')
    const oldContent = _.get(authorizedDigitalSellerChanges, 'old.content', '')
    const newTitle = _.get(authorizedDigitalSellerChanges, 'new.title', '')
    const newContent = _.get(authorizedDigitalSellerChanges, 'new.content', '')

    return (
      <Modal
        closeIcon
        className="AuthorizedDigitalSellers__VersionChangesModal"
        size="fullscreen"
        open={isVersionChangesModalOpen}
        onClose={this.handleVersionChangesModalClose.bind(this)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>ads.txtの変更を比較する</Modal.Header>

        <Modal.Content style={{ minHeight: '70vh', maxHeight: '88vh', overflow: 'auto' }}>
          <ReactDiffViewer
            leftTitle={oldTitle}
            rightTitle={newTitle}
            oldValue={oldContent}
            newValue={newContent}
            compareMethod={DiffMethod.WORDS}
            styles={DIFF_VIEWER_STYLES}
          />
        </Modal.Content>
      </Modal>
    )
  }

  render() {
    const { isBusy, apiError, permission, authorizedDigitalSellerVersions, authorizedDigitalSeller, tableData, isEditModalOpen } = this.state
    const { hasUpdatePermission } = permission
    const apiGetError = isEditModalOpen ? null : apiError

    return (
      <div className="AuthorizedDigitalSellers">
        <Header as="h1">
          <Icon name="buysellads" />

          <Header.Content>ads.txt</Header.Content>
        </Header>

        <Dimmer active={isBusy} inverted>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <ApiErrorMessage error={apiGetError} />

        <Button.Group primary floated="right">
          <Popup
            inverted
            wide
            content="編集"
            trigger={
              <Button
                icon="edit outline"
                type="button"
                disabled={!hasUpdatePermission || !authorizedDigitalSeller || !!apiGetError}
                onClick={this.handleEditModalOpen.bind(this)}
              />
            }
          />

          <Popup
            inverted
            wide
            content="表示"
            trigger={
              <Button
                icon="eye"
                as="a"
                href={`${process.env.REACT_APP_TRILL_WEB_URL}/ads.txt`}
                target="_blank"
                rel="noopener noreferrer"
              />
            }
          />

          <Popup
            inverted
            wide
            content="ダウンロード"
            trigger={<Button icon="cloud download" type="button" disabled={!authorizedDigitalSeller} onClick={this.handleDownloadButtonClick.bind(this)} />}
          />
        </Button.Group>

        <Divider hidden clearing />

        {_.isEmpty(apiGetError) && (
          <Statistic horizontal size="mini" color="grey">
            <Statistic.Value>{tableData.totalItems}</Statistic.Value>
            <Statistic.Label>件</Statistic.Label>
          </Statistic>
        )}

        {!_.isEmpty(authorizedDigitalSellerVersions) && (
          <DataTable
            rowKey="index"
            sort={tableData.sorting}
            onPageChange={this.handleDataTablePageChange}
            onSelectionChange={this.handleDataTableSortChange}
            itemsPerPage={tableData.itemsPerPage}
            currentPage={tableData.currentPage}
            totalPages={tableData.totalPages}
            items={this.authorizedDigitalSellerVersionsDisplayed()}
            cellStyle={{ position: 'relative' }}
            columns={[
              {
                label: 'No.',
                align: 'center',
                field: 'index',
                minWidth: '6em',
              },
              {
                label: 'タイトル',
                field: 'metadata.comment',
                minWidth: '20em',
                collapsing: false,
                render: item => <div style={{ wordBreak: 'break-all' }}>{_.get(item, 'metadata.comment')}</div>,
              },
              {
                label: 'バージョン',
                field: 'version',
                minWidth: '20em',
                collapsing: false,
                render: item => {
                  const { version, isLatest } = item

                  return (
                    <div>
                      {isLatest && <Popup inverted trigger={<Label corner color="blue" icon="star" />} content="最新バージョン" />}
                      {version}
                    </div>
                  )
                },
              },
              {
                label: '更新日時',
                field: 'lastModified',
                minWidth: '10em',
                collapsing: false,
                render: item => (item.lastModified ? moment(item.lastModified).format('YYYY/MM/DD HH:mm') : ''),
              },
              {
                label: '更新者',
                field: 'metadata.lastModifiedByUsername',
                minWidth: '20em',
                collapsing: false,
                render: item => _.get(item, 'metadata.lastModifiedByUsername'),
              },
              {
                label: '操作',
                align: 'center',
                render: item => (
                  <Button.Group secondary>
                    <Popup
                      inverted
                      wide
                      content="閲覧"
                      trigger={
                        <Button
                          icon="zoom-in"
                          onClick={this.handleDetailVersionButtonClick.bind(this, item)}
                        />
                      }
                    />

                    <Popup
                      inverted
                      wide
                      content="ダウンロード"
                      trigger={
                        <Button
                          disabled={!hasUpdatePermission}
                          icon="arrow down"
                          onClick={this.handleDownloadAuthorizedDigitalSellerVersionButtonClick.bind(this, item)}
                        />
                      }
                    />
                  </Button.Group>
                ),
              },
            ]}
          />
        )}

        {this.renderAuthorizedDigitalSellerVersionChangesModal()}

        {this.renderEditAuthorizedDigitalSellerModal()}
      </div>
    )
  }
}

export default AuthorizedDigitalSellers
