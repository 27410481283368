import React, { Component } from 'react'
import {
  Dimmer,
  Loader,
  Header,
  Segment,
  Divider,
  Icon,
  Label,
  Menu,
  Popup,
  Button,
  Checkbox,
  List,
  Sidebar,
  Message,
  Grid,
  Modal,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import 'bootstrap-daterangepicker/daterangepicker.css'
import TextareaAutosize from 'react-textarea-autosize'
import classNames from 'classnames'
import _ from 'lodash'

import MediumInput from '../../components/MediumInput'
import ArticleDescriptionEditor from '../../components/ArticleDescriptionEditor'
import MediumRelatedArticles from '../../components/MediumRelatedArticles'
import ApiErrorMessage from '../../components/ApiErrorMessage'
import TagsDropdown from '../../components/TagsDropdown'
import MediaDropdown from '../../components/MediaDropdown'
import MediumItemsDropdown from '../../components/MediumItemsDropdown'
import SponsorsDropdown from '../../components/SponsorsDropdown'
import CategoriesDropdown from '../../components/CategoriesDropdown'
import ManagementCategoriesDropdown from '../../components/ManagementCategoriesDropdown'
import CreatorsDropdown from '../../components/CreatorsDropdown'
import SeriesDropdown from '../../components/SeriesDropdown'
import TagsCopyModal from '../../components/TagsCopyModal'
import DatetimeTextPicker from '../../components/DatetimeTextPicker'
import moment from 'moment'

import { PUBLISH_ARTICLE_REQUIRE_ATTRIBUTES } from '../../constants/article'
import { PUBLISH_DATETIME_LABEL_FORMAT } from '../../constants/date_format'

import './Article.css'

const getResetPublishDatetime = () => {
  const initialMoment = moment().add(1, 'day')
  const dateTimeMoment = initialMoment.minutes() >= 35 ? initialMoment.add(1, 'hour') : initialMoment
  return moment(`${dateTimeMoment.format('YYYY-MM-DD HH')}:35:00`, 'YYYY-MM-DD HH:mm:ss')
}

/**
 * 記事の状態
 * @enum {string}
 */
const ArticleStatus = {
  /** 公開 */
  PUBLISH: 'publish',
  /** 保留 */
  PENDING: 'pending',
  /** 下書き */
  DRAFT: 'draft',
  /** ゴミ箱 */
  TRASH: 'trash',
}

class Article extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isShowSideBar: false,
      isShowImageDetail: false,
    }
  }

  handleShowSidebar = () => {
    this.setState({ isShowSideBar: true })
  }

  handleHideSidebar = () => {
    this.setState({ isShowSideBar: false })
  }

  /**
   * Show/Hide image detail section (copyright,...)
   */
  handleToggleImageDetailVisibility = () => {
    this.setState({
      isShowImageDetail: !this.state.isShowImageDetail,
    })
  }

  /**
   * 記事の状態表示ラベルをレンダリング
   */
  renderStatusLabel() {
    const labelProps = {
      as: 'a',
      size: 'large',
      style: { verticalAlign: '6px' },
    }

    switch (this.props.articleStatus) {
      case ArticleStatus.PUBLISH:
        return (
          <Label color="green" {...labelProps}>
            <Icon name="unhide" />
            公開
            <Label.Detail># {this.props.articleId}</Label.Detail>
          </Label>
        )
      case ArticleStatus.PENDING:
        return (
          <Label color="red" {...labelProps}>
            <Icon name="hide" />
            保留
            <Label.Detail># {this.props.articleId}</Label.Detail>
          </Label>
        )
      case ArticleStatus.DRAFT:
        return (
          <Label color="yellow" {...labelProps}>
            <Icon name="wait" />
            下書き
            <Label.Detail># {this.props.articleId}</Label.Detail>
          </Label>
        )
      case ArticleStatus.TRASH:
        return (
          <Label color="grey" {...labelProps}>
            <Icon name="trash" />
            ゴミ箱
            <Label.Detail># {this.props.articleId}</Label.Detail>
          </Label>
        )
      default:
        return ''
    }
  }

  /**
   * 記事の下書き一覧をリストでレンダリング
   */
  renderDraftKey() {
    const webUrl = `${process.env.REACT_APP_TRILL_WEB_URL}/articles-preview/${this.props.articleId}`
    return _.map(this.props.draftKeys, draftKey => (
      <List.Item as="li" key={draftKey}>
        <a href={`${webUrl}?key=${encodeURIComponent(draftKey)}`} target="_blank" rel="noopener noreferrer">
          {_.isEqual(this.props.previewKey, draftKey) ? <strong>{draftKey}</strong> : draftKey}
        </a>
      </List.Item>
    ))
  }

  /**
   * Render form fields for article media related data like thumbnail, cover, video, copyright
   */
  renderMediaSection() {
    const formValidationErrors = { isUrl: '無効な URL です' }
    const formErrorLabel = <Label color="red" pointing />

    return (
      <div>
        <Header as="h3" content="画像・動画" />

        {/* サムネイル・カバー画像の設定フィールグループ */}
        <Segment.Group>
          {/* サムネイル画像の設定フィールド */}
          <Segment>
            <Form.Field required error={!_.isNil(this.props.thumbnailImageError)} style={{ marginBottom: 0 }}>
              <label>{this.props.isCoverImageActive ? 'サムネイル画像' : 'サムネイル・カバー画像'}</label>

              <MediumInput
                isDragDrop={true}
                mediumUrl={this.props.thumbnailImageUrlInputValue}
                minWidth={640}
                minHeight={396}
                onChange={this.props.handleThumbnailImageInputChange}
              />

              <Input
                className="isHidden"
                name="thumbnail.image.url"
                placeholder="サムネイル画像を選択してください"
                required
                readOnly
                value={this.props.thumbnailImageUrlInputValue}
              />
            </Form.Field>
          </Segment>

          <Segment>
            {/* Use className=`field` for same CSS as a field */}
            <div className="field">
              <label style={{ cursor: 'pointer', marginBottom: 0 }} onClick={this.handleToggleImageDetailVisibility}>
                <Icon name={this.state.isShowImageDetail ? 'caret down' : 'caret right'} />
                サムネイルの詳細設定
              </label>
            </div>
          </Segment>

          <Segment
            className={classNames({
              isHidden: !this.state.isShowImageDetail || !this.props.thumbnailImageUrlInputValue,
            })}
          >
            <Form.Input name="thumbnail.image.copyright.title" label="出典元" placeholder="出典元を入力してください" />

            <Form.Input
              name="thumbnail.image.copyright.url"
              label="出典元の URL"
              placeholder="出典元の URL を入力してください"
              validations="isUrl"
              validationErrors={formValidationErrors}
              errorLabel={formErrorLabel}
            />
          </Segment>

          {/* カバー画像を別の画像に設定する場合のトグル */}
          <Segment
            className={classNames({
              isHidden: !this.state.isShowImageDetail,
            })}
          >
            <Checkbox
              toggle
              label="カバー画像を別の画像に設定"
              checked={this.props.isCoverImageActive}
              onChange={this.props.handleCoverImageToggleChange}
            />
          </Segment>

          {/* カバー画像の設定フィールド */}
          <Segment
            className={classNames({
              isHidden: !this.state.isShowImageDetail || !this.props.isCoverImageActive,
            })}
          >
            <Form.Field error={!_.isNil(this.props.coverImageError)} style={{ marginBottom: 0 }}>
              <label>カバー画像</label>

              <MediumInput
                isDragDrop={true}
                mediumUrl={this.props.coverImageUrlInputValue}
                minWidth={640}
                minHeight={396}
                onChange={this.props.handleCoverImageInputChange}
              />

              <Input
                className="isHidden"
                name="cover.image.url"
                placeholder="カバー画像を選択してください"
                readOnly
                value={this.props.coverImageUrlInputValue}
              />
            </Form.Field>

            <div
              className={classNames({
                isHidden: !this.props.coverImageUrlInputValue,
              })}
              style={{ marginTop: '1rem' }}
            >
              <Form.Input name="cover.image.copyright.title" label="出典元" placeholder="出典元を入力してください" />

              <Form.Input
                name="cover.image.copyright.url"
                label="出典元の URL"
                placeholder="出典元の URL を入力してください"
                validations="isUrl"
                validationErrors={formValidationErrors}
                errorLabel={formErrorLabel}
              />
            </div>
          </Segment>
        </Segment.Group>
      </div>
    )
  }

  /**
   * Render form fields for article detail related data like publish datetime, medium, category,...
   */
  renderDetailSection() {
    const formErrorLabel = <Label color="red" pointing />

    return (
      <div>
        <Header as="h3" content="記事情報" />

        {/* 公開日時入力フィールド */}
        <Form.Field style={{ position: 'relative' }}>
          <label>公開日時</label>
          <Button
            circular
            basic
            color="blue"
            type="button"
            size="mini"
            onClick={this.props.handlePublishDatetimeSetToday}
            style={{ position: 'absolute', top: '-0.5rem', right: 0 }}
          >
            今日
          </Button>

          <DatetimeTextPicker
            name="publishDatetime"
            value={this.props.publishDatetime}
            format={PUBLISH_DATETIME_LABEL_FORMAT}
            resetValue={getResetPublishDatetime()}
            placeholder="公開日時を指定"
            invalidDatetimeMessage="公開日時の形式が不正です。yyyymmdd hhmmの形式で入力してください。例）20250101 1435"
            invalidDatetimeMessagePosition="top left"
            onChange={this.props.handlePublishDatetimeChange}
            onInvalid={this.props.handlePublishDatetimeInvalid}
          />
        </Form.Field>

        <Divider section />

        <Header as="h6" color="grey">
          メディア
        </Header>

        {/* メディア選択フィールド */}
        <Form.Field required>
          <label>メディア名</label>

          <MediaDropdown mediumId={this.props.mediumId} onChange={this.props.handleMediaDropdownChange} required />

          {/* null だと値があると判定されてしまうため undefined を入れる */}
          <Form.Input
            className="isHidden"
            required
            name="mediumId"
            value={this.props.mediumId ? this.props.mediumId : undefined}
          />
        </Form.Field>

        <Form.Field required>
          <label>メディアの設定</label>

          <MediumItemsDropdown
            mediumId={this.props.mediumId}
            mediumItemId={this.props.mediumItemId}
            onChange={this.props.handleMediumItemDropdownChange}
            required
          />

          {/* null だと値があると判定されてしまうため undefined を入れる */}
          <Form.Input
            className="isHidden"
            required
            name="mediumItemId"
            value={this.props.mediumItemId ? this.props.mediumItemId : undefined}
          />
        </Form.Field>

        <Form.Field hidden={!_.get(this.props.medium, 'isManga', false)}>
          <label>プロフィール</label>

          <CreatorsDropdown creator={this.props.creator} onChange={this.props.handleCreatorDropdownChange} />

          <Form.Input className="isHidden" name="creatorId" value={_.get(this.props.creator, 'id', undefined)} />
        </Form.Field>

        <Form.Field hidden={!_.get(this.props.medium, 'isManga', false)}>
          <label>連載タイトル</label>

          <SeriesDropdown
            series={this.props.series}
            creatorIdSearchParams={_.get(this.props.creator, 'id', undefined)}
            onChange={this.props.handleSeriesDropdownChange}
          />

          <Form.Input className="isHidden" name="seriesId" value={_.get(this.props.series, 'id', undefined)} />
        </Form.Field>

        <Divider section />

        {/* 管理用カテゴリー設定フィールド */}
        <Form.Field>
          <label>管理用カテゴリ</label>

          <ManagementCategoriesDropdown
            managementCategoryId={this.props.managementCategoryId}
            onChange={this.props.handleManagementCategoriesDropdownChange}
          />

          <Form.Input className="isHidden" name="managementCategoryId" value={this.props.managementCategoryId} />
        </Form.Field>

        <Divider section />

        {/* カテゴリ選択フィールド */}
        <Form.Field>
          <label>カテゴリ</label>

          <CategoriesDropdown categoryId={this.props.categoryId} onChange={this.props.handleCategoriesDropdownChange} />

          <Form.Input className="isHidden" name="categoryId" value={this.props.categoryId} />
        </Form.Field>

        <Divider section />

        <Form.Field>
          <Grid>
            <Grid.Column width={3}>
              <Header as="h6" color="grey">
                タグ
              </Header>
            </Grid.Column>
            <Grid.Column width={13} textAlign="right">
              <TagsCopyModal
                trigger={
                  <Button circular color="blue" type="button" size="mini">
                    <Icon name="copy" link />
                    他の記事からタグをコピー
                  </Button>
                }
                mergeTagsByArticleId={this.props.mergeTagsByArticleId}
              />
            </Grid.Column>
          </Grid>
        </Form.Field>

        {/* 記事のタイトルに関連するタグ設定フィールド */}
        <Form.Field>
          <label>記事のタイトルに関連するタグ</label>

          <TagsDropdown value={this.props.titleTagsDropdownValue} onChange={this.props.handleTitleTagsDropdownChange} />
        </Form.Field>

        {/* 記事の本文に関連するタグ設定フィールド */}
        <Form.Field>
          <label>記事の本文に関連するタグ</label>

          <TagsDropdown
            value={this.props.descriptionTagsDropdownValue}
            onChange={this.props.handleDescriptionTagsDropdownChange}
          />
        </Form.Field>

        <Divider section />

        <Form.Field>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexFlow: 'wrap',
              marginBottom: '0.5rem',
            }}
          >
            <label>Pinterest自動投稿</label>
            {this.props.isShowPinterestRssLink && (
              <a
                href={`${process.env.REACT_APP_TRILL_WEB_URL}/pinterest/articles/${this.props.articleId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Pinterest RSS <Icon name="external alternate" />
              </a>
            )}
          </div>

          <Form.Checkbox toggle name="isPinterestAutoPublish" />
        </Form.Field>

        <Divider section />

        {/* スラッグ入力フィールド */}
        <Popup
          inverted
          trigger={
            <Form.Input
              name="slug"
              label="スラッグ"
              placeholder="スラッグを入力してください"
              validations={{ matchRegexp: /^[a-z0-9-]*$/i }}
              validationErrors={{
                matchRegexp: '英数字または - で入力してください。',
              }}
              errorLabel={formErrorLabel}
            />
          }
          content="URLの最後に表示される文字を設定できます。"
        />

        <Divider section />

        <Form.Field disabled={!!this.props.sponsorId}>
          <label>アフィリエイト記事</label>

          <Form.Checkbox toggle name="isAffiliated" checked={this.props.isAffiliated} />
        </Form.Field>

        <Divider section />

        {/* スポンサー選択フィールド */}
        <Form.Field>
          <label>スポンサー</label>

          <SponsorsDropdown
            sponsorId={this.props.sponsorId}
            onChange={this.props.handleSponsorDropdownChange}
            viewSavingArticleMaximum={true}
          />

          <Form.Input className="isHidden" value={this.props.sponsorId} name="sponsorId" />
        </Form.Field>

        <Divider hidden />
      </div>
    )
  }

  /**
   * Render form fields for article preview key
   */
  renderPreviewSection() {
    return (
      <div>
        <Header as="h3" content="プレビュー" />

        <Segment padded>
          <Label attached="top left">プレビュー</Label>
          <List as="ul">{this.renderDraftKey()}</List>
          {/* Preview 時に必要となる previewKey 入力フィールド */}
          <Form.Field error={this.props.isConflictDraftKey}>
            <Form.Input
              name="originalArticle.previewKey"
              label="プレビュー用の Key を入力してください"
              placeholder="Preview として使用する key を入力してください"
              onChange={this.props.handlePreviewKeyChange}
            />
          </Form.Field>
        </Segment>

        <Divider hidden />
      </div>
    )
  }

  render() {
    const isDisablePublishArticleButton = _.some(PUBLISH_ARTICLE_REQUIRE_ATTRIBUTES, attribute => {
      /**
       * Example attribute: title, mediumId,...
       * Example transformed attribute: thumbnailImageUrl(thumbnail.image.url),...
       */
      const transformedAttribute = attribute.replace(/([A-Z])/g, '.$1').toLowerCase()
      const keys = [attribute, transformedAttribute]
      const isExist = _.some(keys, key => !!_.get(this.props.initialFormValues, key))

      return !isExist
    })

    return (
      <div className="Article">
        <div className="Article__Main">
          <Grid className="Article__Main__Header">
            <Grid.Column width={10}>
              <Header as="h1">
                <Icon name="file text outline" />

                <Header.Content>{this.props.isNew ? '記事の作成' : '記事の編集'}</Header.Content>

                {/* 記事の現在の状態と変更メニュー */}
                <Popup
                  position="bottom left"
                  style={{
                    padding: '0',
                    border: 'none',
                  }}
                  hoverable
                  trigger={this.renderStatusLabel()}
                >
                  <Popup.Content>
                    <Menu vertical borderless compact>
                      {this.props.articleStatus !== ArticleStatus.PUBLISH && (
                        <Menu.Item
                          name={ArticleStatus.PUBLISH}
                          icon="unhide"
                          content="公開する"
                          disabled={isDisablePublishArticleButton}
                          onClick={this.props.handleStatusMenuItemClick}
                        />
                      )}
                      {this.props.articleStatus !== ArticleStatus.PENDING && (
                        <Menu.Item
                          name={ArticleStatus.PENDING}
                          icon="hide"
                          content="保留にする"
                          onClick={this.props.handleStatusMenuItemClick}
                        />
                      )}
                      {this.props.articleStatus !== ArticleStatus.DRAFT && (
                        <Menu.Item
                          name={ArticleStatus.DRAFT}
                          icon="wait"
                          content="下書きに戻す"
                          onClick={this.props.handleStatusMenuItemClick}
                        />
                      )}
                      {this.props.articleStatus !== ArticleStatus.TRASH && (
                        <Menu.Item
                          name={ArticleStatus.TRASH}
                          icon="trash"
                          content="ゴミ箱へ移動"
                          onClick={this.props.handleStatusMenuItemClick}
                        />
                      )}
                    </Menu>
                  </Popup.Content>
                </Popup>
              </Header>
            </Grid.Column>

            <Grid.Column width={6} textAlign="right">
              <Button icon="setting" onClick={this.handleShowSidebar} />

              <Button.Group secondary>
                {this.props.originalArticleLink && (
                  <Popup
                    inverted
                    wide
                    content="元記事を見る"
                    trigger={
                      <Button
                        icon="file alternate outline"
                        as="a"
                        href={this.props.originalArticleLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    }
                  />
                )}

                {this.props.outsourcedArticleId && (
                  <Popup
                    inverted
                    wide
                    content="編集委託記事"
                    trigger={
                      <Button
                        icon="users"
                        as="a"
                        href={`${process.env.REACT_APP_TRILL_OUTSOURCE_URL}/article/${this.props.articleId}?token=${this.props.outsourcedArticleId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    }
                  />
                )}

                <Popup
                  inverted
                  wide
                  content="プレビュー"
                  trigger={
                    <Button
                      icon="zoom-in"
                      as="a"
                      href={`/article-preview/${this.props.articleId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      disabled={this.props.isDisableArticlePreviewButton}
                    />
                  }
                />

                <Popup
                  inverted
                  wide
                  content="TRILL Webサイトで見る"
                  trigger={
                    <Button
                      icon="world"
                      as="a"
                      href={`${process.env.REACT_APP_TRILL_WEB_URL}/articles/${this.props.articleId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      disabled={!_.isEqual(this.props.articleStatus, 'publish')}
                    />
                  }
                />
              </Button.Group>
            </Grid.Column>
          </Grid>

          <Grid className="Article__Main__RevertSection">
            <Grid.Column>
              {this.props.isNew || this.props.outsourcedArticleId ? null : (
                <Button
                  basic
                  content="自分の下書きを破棄する"
                  disabled={!this.props.draftExists}
                  onClick={this.props.revertChange}
                />
              )}
            </Grid.Column>
          </Grid>
        </div>

        <Dimmer active={this.props.isBusy} inverted page={this.props.isDuringSubmit}>
          <Loader>読み込み中</Loader>
        </Dimmer>

        <Form
          ref={this.props.setRef}
          noValidate
          onChange={this.props.handleFormChange}
          onValid={this.props.handleFormValid}
          onInvalid={this.props.handleFormInvalid}
          onValidSubmit={this.props.handleFormValidSubmit}
        >
          <div className="Article__Main">
            {this.props.isNew ? null : <Divider />}

            {/* タイトル入力フィールド */}
            <Form.Input
              name="title"
              label="タイトル"
              placeholder="タイトルを入力してください"
              onChange={e => this.props.debouncedTitleAutoSave(e.target.value)}
              required
              readOnly={this.props.outsourcedArticleId}
            />

            {/* 概要入力フィールド */}
            <Form.Field
              name="summary"
              label="概要"
              placeholder="概要を入力してください"
              control={TextareaAutosize}
              minRows={1}
              maxRows={1000}
              rows={1000}
              onChange={e => this.props.handleSummaryChange(e.target.value)}
              required={this.props.isNew}
              readOnly={this.props.outsourcedArticleId}
              value={this.props.summary}
            />

            {/* 本文入力フィールド */}
            <Form.Field required error={!_.isNil(this.props.descriptionError)}>
              <label>本文</label>

              <Segment
                className={classNames('Article__Description', {
                  error: this.props.descriptionError,
                })}
              >
                {this.props.isArticleInitialized && (
                  <ArticleDescriptionEditor
                    description={this.props.descriptionInputValue}
                    onInit={this.props.handleDescriptionEditorInit}
                    onChange={this.props.handleDescriptionEditorChange}
                    autoSave={description => this.props.debouncedDescriptionAutoSave(description)}
                    fireByteLengthWarning={this.props.handleEditorWarning}
                    readOnly={this.props.outsourcedArticleId}
                  />
                )}

                {!_.isNil(this.props.descriptionError) && (
                  <Label className="Article__DescriptionErrorLabel" color="red" attached="top right">
                    {this.props.descriptionError.message}
                  </Label>
                )}
              </Segment>

              <Input
                name="description"
                placeholder="本文を入力してください"
                required
                readOnly
                value={this.props.descriptionInputValue}
              />
            </Form.Field>
            <Form.Field>
              <label>メディア関連記事</label>
              <Segment>
                <MediumRelatedArticles
                  articles={this.props.mediumRelatedArticles}
                  onChange={this.props.handleMediumRelatedArticlesChange}
                />
                <Input
                  className="isHidden"
                  name="mediumRelatedArticles"
                  readOnly
                  value={this.props.mediumRelatedArticlesInputValue}
                />
              </Segment>
            </Form.Field>
          </div>

          <Menu
            vertical
            fixed="right"
            className={`Article__Sidebar${this.state.isShowSideBar ? ' Article__Sidebar--Visible' : ''}`}
          >
            {/* TODO: 記事の最終更新日時を表示 */}
            {/* TODO: 更新履歴を表示する機能 */}

            {/* 更新（新規作成時は保存）ボタン */}
            <Button
              type="submit"
              content={this.props.isNew ? '下書きとして保存' : '更新'}
              icon="save"
              labelPosition="left"
              primary
              fluid
              disabled={
                !this.props.isFormValid ||
                !this.props.isSummaryValid ||
                this.props.isDuringSubmit ||
                (!this.props.draftExists &&
                  !this.props.isFormModified &&
                  !this.props.isTitleTagModified &&
                  !this.props.isDescriptionTagModified &&
                  !this.props.isSummaryModified) ||
                this.props.isConflictDraftKey ||
                this.props.descriptionError
              }
            />

            <Modal
              className="Article__WarningModal"
              size="small"
              closeIcon
              open={this.props.isWarningModalOpen}
              onClose={this.props.handleWarningModalClose}
              closeOnDimmerClick={false}
            >
              <Modal.Header>記事の編集</Modal.Header>

              <Modal.Content>
                <Header style={{ padding: '0 0 2rem' }}>
                  <div dangerouslySetInnerHTML={{ __html: this.props.warningModalMessage }}></div>
                </Header>
              </Modal.Content>

              <Modal.Actions>
                <Button content="キャンセル" onClick={this.props.handleCancelButtonWarningModalClick} />
                <Button primary content="保存" onClick={this.props.handleSubmitButtonWarningModalClick} />
              </Modal.Actions>
            </Modal>

            <ApiErrorMessage error={this.props.apiError} />

            <Divider />

            <Menu pointing secondary color="blue">
              <Menu.Item
                content="記事情報"
                name="meta"
                onClick={this.props.handleSidebarMenuItemClick}
                active={_.isEqual(this.props.viewSidebarStatus, 'meta')}
              />
              <Menu.Item
                content="プレビュー"
                name="preview"
                onClick={this.props.handleSidebarMenuItemClick}
                active={_.isEqual(this.props.viewSidebarStatus, 'preview')}
              />
            </Menu>

            <Divider hidden />

            <div
              style={{
                display: _.isEqual(this.props.viewSidebarStatus, 'meta') ? '' : 'none',
              }}
            >
              {this.renderMediaSection()}

              <Divider hidden />

              {this.renderDetailSection()}
            </div>

            <div
              style={{
                display: _.isEqual(this.props.viewSidebarStatus, 'preview') ? '' : 'none',
              }}
            >
              {this.renderPreviewSection()}
            </div>
          </Menu>
        </Form>

        <Sidebar as={Message} warning animation="overlay" direction="bottom" visible={this.props.editorWarning}>
          記事の執筆お疲れ様です
          <span role="img" aria-label="teacup">
            &#x1f375;
          </span>
          &#xA0;通信制限が近づいているので、そろそろ記事の
          {this.props.isNew ? '下書きとして保存' : '更新'}ボタンを押してください
          <span role="img" aria-label="folded hands">
            &#x1f64f;
          </span>
        </Sidebar>

        <div
          className={`Article__SidebarBackdrop${this.state.isShowSideBar ? ' Article__SidebarBackdrop--Visible' : ''}`}
          onClick={this.handleHideSidebar}
        >
          <Button icon="close" className="Article__SidebarBackdrop__CloseButton" onClick={this.handleHideSidebar} />
        </div>
      </div>
    )
  }
}

export default Article
